import React, { useEffect, useState } from "react";
import "./Banner.scss";

function Banner({ top = "0px" }) {
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  const handleClose = () => {
    setIsBannerVisible(false);
    sessionStorage.setItem("isBannerClosed", "true");
  };

  useEffect(() => {
    const isBannerClosed = sessionStorage.getItem("isBannerClosed");
    if (!isBannerClosed) {
      const timer = setTimeout(() => {
        setIsBannerVisible(true);
      }, 250);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div
      className={`banner ${isBannerVisible ? "" : "hidden"}`}
      style={{ top }}
    >
      <div className="banner-content">
        <span>Discover insights from 2500+ consumers on shopping and messaging trends. &rarr; </span>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.wunderkind.co/knowledge-hub/consumer-insights/"
          className="banner-register-now"
        >
          Get all the data.
          {/* <img src="/images/ArrowBanner.svg" alt="Arrow" /> */}
        </a>
        <a className="banner-close-button" onClick={handleClose}>
          X
        </a>
      </div>
    </div>
  );
}

export default Banner;
